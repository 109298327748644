import React from 'react'
import { graphql } from 'gatsby'
import Link from '../../utilities/link'
import CareerHeader from '../../components/header/careerHeader'
import i18n from '../../i18n'
import SiteNavigation from '../../components/header/siteNavigation'
import CommonFooter from '../../components/footer/commonFooter'
import Seo from '../../components/seo/seo'
import '../../layouts/custom.scss'
import { useTranslation } from 'react-i18next'
import { usePageContext } from '../../pageContext'
import Card from '../../components/common/card'

const Stage = ({ data }) => {
  const { t } = useTranslation();
  const { lang } = usePageContext();
  return (
    <>
      <Seo
        title={t('stage.seoTitle')}
        description={t('stage.seoDescription')}
        seoImage={data.lavoraConNoi.childImageSharp.resize.src}
      />
      <SiteNavigation />
      <CareerHeader
        headerTitle={t('stage.introTitle')}
        headerSubtitle={t('stage.introDesc')}
        fluid={data.lavoraConNoi.childImageSharp.fluid}
        alt={t('stage.heroImageAltTag')}
      />
      <div className='py-12 md:py-24'>
        <div className="section">
          {data.allMarkdownRemark.group.map(({ fieldValue, edges }) => {
            return (
              <div className="md:flex md:space-x-16">
                <div className='md:w-1/3'>
                  <h3 className="text-3xl font-semibold border-b-2 border-green-400 pb-4">{fieldValue}</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  {edges
                    .filter(({ node }) => {
                      return (
                        node.frontmatter.lang ===
                        lang
                      )
                    })
                    .map(({ node }) => {
                      return (
                        <Card
                          cardTitle={node.frontmatter.name}
                          cardSubtitle={node.frontmatter.headline}
                          to={`/${node.frontmatter.translatedPath}`}
                          button={t('careers.jobButton')}
                        />
                      )
                    })}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <CommonFooter />
    </>
  )
}

export default Stage

export const careerQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: "stage" } } }) {
      group(field: frontmatter___family) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              name
              headline
              translatedPath
              lang
            }
          }
        }
      }
    }
    lavoraConNoi: file(relativePath: { eq: "lavora-con-noi/lavora-con-noi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
