import React from 'react'
import { StaticQuery, Link } from 'gatsby'
import SiteNavigation from './siteNavigation'
import Img from 'gatsby-image'

const CareerHeader = props => (
  <>
    <SiteNavigation />
    <div className="maingrad is-relative py-14">
      <Img
        fluid={props.fluid}
        style={{
          position: 'absolute',
          overflow: 'hidden',
          zIndex: '0',
        }}
        imgStyle={{
          width: '100%',
          height: '100%',
          opacity: '0.2',
        }}
        className="image-wrapper-height-auto"
        alt={props.alt}
      />
      <div className='container pb-6 pt-20'>
          <h1 className="px-8 md:px-0 mb-8 text-5xl text-white capitalize font-semibold">{props.headerTitle}</h1>
          <h2 className="px-8 text-xl md:bg-gray-50 font-semibold p-2 md:rounded-full">{props.headerSubtitle}</h2>
      </div>
    </div>
  </>
)

export default CareerHeader
