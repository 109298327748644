import React from 'react'
import Img from 'gatsby-image'
import Link from '../../utilities/link'
import SlideInTop from '../utility/slideInTopAnimation'

const Card = props => (
    <div className="bg-gray-50 p-8 drop-shadow-md rounded-xl transition ease-in-out duration-150 hover:scale-110 h-full">
      <div className="flex flex-col">
        {props.fixedImage && (
            <Img
              css={props.imageCss}
              style={props.imageStyle}
              fixed={props.fixedImage}
              alt={props.alt}
            />
        )}
        <div>
          <h3 className='text-xl font-semibold pb-4 border-b-2 border-emerald-500'>{props.cardTitle}</h3>
          <p className='pt-4'>{props.cardSubtitle}</p>    
        </div>
      </div>
      {props.button &&  (
        <div className='mt-8'>
          <Link to={props.to} className="greenBtnNormal">
            {props.button}
          </Link>
        </div>
      )}
    </div>
)

export default Card
